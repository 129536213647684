/*===================NAV=====================*/


/* GLOBAL STYLES
-------------------------------------------------- */


/* Padding below the footer and lighter body text */

body {
    padding-bottom: 40px;
    color: #5a5a5a;
}


/* Aanpassingen footer */

.footer-box ul {
    padding: 0px;
    font-size: 19px;
    list-style: none;
    margin-bottom: 15px;
}

.footer-box a:last-child img {
    margin-bottom: 0px;
}

.footer-box a {
    color: #5a5a5a;
}

.footer-box img {
    display: inline-block;
    margin-bottom: 30px;
}

.footer-box h4 {
    font-family: "lato";
    font-size: 23px;
    text-transform: uppercase;
    margin-bottom: 35px;
}

@media (min-width: 998px) {
    .navbar-toggle {
        display: none;
    }
}


/* CUSTOMIZE THE NAVBAR
-------------------------------------------------- */


/* Special class on .container surrounding .navbar, used for positioning it into place. */

.navbar-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 20;
}


/* Flip around the padding for proper display in narrow viewports */

.navbar-wrapper > .container {
    padding-right: 0;
    padding-left: 0;
}

.navbar-wrapper .navbar {
    padding-right: 15px;
    padding-left: 15px;
}

.navbar-wrapper .navbar .container {
    width: auto;
    font-size: 1.2em;
    font-family: 'lato';
}


/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */


/* Carousel base class */

.carousel {
    height: 600px;
    margin-bottom: 30px;
    overflow: hidden;
}


/* Since positioning the image, we need to help out the caption */

.carousel-caption {
    z-index: 10;
}


/* Declare heights because of positioning of img element */

.carousel .item {
    width: 100%;
    background-color: #777;
}

.carousel-inner > .item > img {
    top: 0;
    left: 0;
    min-width: 100%;
}

@media (max-width: 1300px) {
    .carousel {
        height: auto;
    }
}


/* MARKETING CONTENT
-------------------------------------------------- */


/* Center align the text within the three columns below the carousel */

.marketing .col-lg-4 {
    margin-bottom: 20px;
    text-align: center;
}

.marketing h2 {
    font-weight: normal;
    font-family: "lato";
    text-transform: uppercase;
}

.marketing .col-lg-4 p {
    margin-right: 10px;
    margin-left: 10px;
}


/* Featurettes
------------------------- */

.featurette-divider {
    margin: 60px 0;
    /* Space out the Bootstrap <hr> more */
}


/* Thin out the marketing headings */

.featurette-heading {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -1px;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 768px) {
    /* Navbar positioning foo */
    .navbar-wrapper {
        margin-top: 20px;
    }
    .navbar-wrapper .container {
        padding-right: 15px;
        padding-left: 15px;
    }
    .navbar-wrapper .navbar {
        padding-right: 0;
        padding-left: 0;
    }
    /* The navbar becomes detached from the top, so we round the corners */
    .navbar-wrapper .navbar {
        border-radius: 4px;
    }
    /* Bump up size of carousel content */
    .carousel-caption p {
        margin-bottom: 20px;
        font-size: 21px;
        line-height: 1.4;
    }
    .featurette-heading {
        font-size: 45px;
    }
}

@media (min-width: 992px) {
    .featurette-heading {
        margin-top: 10px;
    }
}

@media (max-width: 1199px) {
    .navbar-header {
        width: 100%;
    }
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
        margin: 0px;
        width: 100%;
        text-align: center;
    }
    .navbar-brand > img {
        display: inline-block;
    }
}

@media (max-width: 992px) {
    .navbar > .container .navbar-brand,
    .navbar > .container-fluid .navbar-brand {
        margin: 0px;
        width: auto;
        text-align: center;
    }
    .navbar-toggle {
        margin-right: -15px;
    }
}

@media (max-width: 767px) {
    .navbar-wrapper .navbar .container {
        width: 100%;
        padding: 0px;
    }
    .container > .navbar-header,
    .container-fluid > .navbar-header,
    .container > .navbar-collapse,
    .container-fluid > .navbar-collapse {
        margin: 0px;
    }
    .footer-box {
        margin-bottom: 40px;
    }
    .footer-box img {
        margin: auto;
        margin-bottom: 30px;
    }
    .navbar-toggle {
        margin-right: 0px;
    }
    .footer-box img {
        display: block;
    }
    .carousel {
        margin-top: 64px;
    }
}


/*=================BUTTONS=================*/


/*=================WALL====================*/


/*============GENERAL=============*/

body {
    color: #4b4b4a;
    font-size: 18px;
    font-family: 'lato', Arial;
}

a:hover,
a:focus {
    text-decoration: none;
}

.link,
.wall .thumbnail.record .record-reactie .item .item-reactie a,
.toevoegen {
    color: #4b4b4a;
    font-family: 'lato', Arial;
    text-transform: none;
}

.link:hover,
.wall .thumbnail.record .record-reactie .item .item-reactie a:hover,
.toevoegen:hover,
.linkfocus,
.link:visited,
.wall .thumbnail.record .record-reactie .item .item-reactie a:visited,
.toevoegen:visited {
    color: #4b4b4a;
    text-decoration: none;
}

.info-link,
.request-item .request-comments button,
.request-item .request-button-group .request-need-people form button {
    background-color: #fff;
    color: #0ad79b;
    padding: 0px 10px;
    border-radius: 4px;
    display: inline-block;
    text-transform: none;
    position: relative;
    transition: all 0.5s;
}

.info-link:hover,
.request-item .request-comments button:hover,
.request-item .request-button-group .request-need-people form button:hover,
.info-link:focus,
.request-item .request-comments button:focus,
.request-item .request-button-group .request-need-people form button:focus,
.info-link:active,
.request-item .request-comments button:active,
.request-item .request-button-group .request-need-people form button:active {
    background-color: #f7f7f7;
    color: #38f6be;
    text-decoration: none;
}

.info-link .badge,
.request-item .request-comments button .badge,
.request-item .request-button-group .request-need-people form button .badge {
    background-color: #fd2805;
    padding: 3px 0;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 10px;
    width: 16px;
    height: 16px;
}

.alert {
    margin: 10px 0;
}

button.info-link,
.request-item .request-comments button,
.request-item .request-button-group .request-need-people form button {
    border: none;
}

.vert-line:after {
    content: "|";
    padding-left: 5px;
    padding-right: 5px;
}

.form-horizontal .control-label {
    text-align: left;
    font-family: 'lato', Arial;
    color: #4b4b4a;
    font-weight: normal;
    padding-top: 12px;
}

/*.form-horizontal input,
.form-horizontal select {
    height: 50px;
    border-radius: 0;
    background-color: rgba(238, 238, 238, 0.5);
    font-size: 18px;
}*/

.form-horizontal textarea {
    height: 245px;
    border-radius: 0;
    resize: vertical;
    background-color: rgba(238, 238, 238, 0.5);
    font-size: 18px;
}

.form-horizontal button[type="submit"] {
    margin-top: 0px;
}

h2 {
    font-family: 'lato', Arial;
    font-size: 33px;
}

.well {
    background-color: #fff;
    padding: 15px;
    border-radius: 0;
}

h2.title {
    font-size: 28px;
    margin-bottom: 25px;
    margin-top: 10px;
}

section.main {
    overflow: hidden;
}

.succsess-send {
    margin: 10% 0;
    display: inline-block;
    padding: 15px 40px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px #ccc;
    background-color: rgba(238, 238, 238, 0.8);
}

.succsess-send i {
    color: #4DA568;
    font-size: 40px;
}

.succsess-send p {
    font-size: 25px;
}

.list-group.student-list-data {
    margin-top: 20px;
}

.list-group.student-list-data .list-group-item {
    background-color: rgba(238, 238, 238, 0.8);
    border-radius: 0;
    height: 50px;
}

.list-group.student-list-data .list-group-item .list-group-item-heading {
    font-family: aller_bd;
}

.list-group.student-list-data form {
    margin-top: 10px;
}

.help-block {
    color: red;
}

.empty-content {
    font-size: 22px;
    text-align: center;
    margin: 15px 0;
}

hr {
    margin: 10px 0;
}

.modal-dialog .modal-content {
    overflow: hidden;
}

.modal-dialog .modal-content .preloader {
    z-index: 5000;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding: 90% 0 0 0;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    display: none;
}

.youAccept {
    color: #0ad79b;
}

.delete-button-class,
.request-comment-item .delete-request-comment button,
.reacties-item .delete-comment-request button,
.request-item .delete-request button,
.thumbnail.record .remove-post button,
.thumbnail.record .item.comment .delete-comment-reaction button,
.spelen-item .delete-game button {
    background-color: #FFFFFF !important;
    border: none;
    color: #0ad79b !important;
    width: 30px;
    height: 30px;
    opacity: 0;
}

.delete-button-class-hover,
.request-comment-item:hover .delete-request-comment button,
.reacties-item:hover .delete-comment-request button,
.thumbnail.record:hover .remove-post button,
.thumbnail.record .item.comment:hover .delete-comment-reaction button,
.spelen-item:hover .delete-game button,
.request-item:hover .delete-request button {
    cursor: pointer;
    opacity: 1;
    transition: opacity .7s;
}

.delete-button-class-hover:hover,
.request-comment-item:hover .delete-request-comment button:hover,
.reacties-item:hover .delete-comment-request button:hover,
.thumbnail.record:hover .remove-post button:hover,
.thumbnail.record .item.comment:hover .delete-comment-reaction button:hover,
.spelen-item:hover .delete-game button:hover,
.request-item:hover .delete-request button:hover {
    cursor: pointer;
}

.request-comment-item .delete-request-comment {
    float: right;
}

.request-comment-item .delete-request-comment button {
    border-radius: 0 !important;
    padding: 0 !important;
}

.request-comment-item .delete-request-comment button:hover,
.request-comment-item .delete-request-comment button:active,
.request-comment-item .delete-request-comment button:focus {
    color: #fff;
    background-color: #D62C1A;
}

.reacties-item .delete-comment-request {
    position: absolute;
    right: 0px;
}

.request-item {
    position: relative;
}

.request-item .request-author {
    margin-top: 2px;
    margin-bottom: 3px;
}

.request-item .delete-request {
    position: absolute;
    right: 10px;
    z-index: 1;
}

.request-item .close-request {
    text-align: right;
    position: absolute;
    right: 50px;
    z-index: 5;
}

.request-item .close-request button {
    background: #FFFFFF;
    border: none;
    color: #0ad79b;
    height: 30px;
    outline: none;
}

.error-text,
.error-title,
.error-scheduledDate {
    color: #D62C1A;
    text-transform: none;
}

.has-error input,
.has-error textarea {
    border: 1px solid #D62C1A !important;
}


/*-------header-------*/

.navbar-wrapper {
    position: relative;
    z-index: 4000;
}

.navbar-wrapper .navbar-default {
    background-color: #222222;
    font-size: 15px;
    min-height: 65px;
    border: none;
}

.navbar-wrapper .navbar-default .navbar-brand {
    padding: 8px 15px;
}

.navbar-wrapper .navbar-default .navbar-toggle {
    margin-top: 12px;
    margin-bottom: 12px;
}

.navbar-wrapper .navbar-default .navbar-collapse {
    padding: 15px 30px;
}

.navbar-wrapper .navbar-default li a {
    color: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
    font: 18px normal 'lato', Arial, Arial, sans-serif;
    text-transform: uppercase;
    transition: all 0.3s;
}

.navbar-wrapper .navbar-default li a:hover {
    color: #d874a3;
}

.navbar-wrapper .navbar-default li.active a,
.navbar-wrapper .navbar-default li.active a:hover,
.navbar-wrapper .navbar-default li.active a:focus {
    color: #fff;
    background-color: #0ad79b;
}

.header-wrapper {
    position: relative;
    top: -90px;
}

.header-wrapper .header-text {
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    margin-bottom: 40px;
    font-family: nexa_rust_sansblack;
    font-size: 20px;
    line-height: 20px;
    left: 55%;
    right: 25%;
    text-shadow: 0px 2px 2px rgba(5, 113, 81, 0.6);
}

.header-wrapper img {
    max-width: 100%;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #000;
}


/*-------buttons------*/

.btn {
    font-family: 'lato', Arial;
    text-transform: uppercase;
    font-size: 18px;
    color: #222222;
    background-color: #fff;
}

.btn:focus {
    outline: none !important;
}

.btn-lg,
.btn-group-lg > .btn {
    padding: 16px;
    border-radius: 0;
}

.btn-default,
.btn-default:hover,
.btn-default:focus {
    color: #fff;
    background-color: #4b4b4a;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    background-color: #4b4b4a;
    color: #fff;
}

.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
    background-color: #4b4b4a;
    color: #fff;
}

.btn-primary {
    color: #222222;
    background-color: #0ad79b !important;
    border: none;
    outline: none;
    border-radius: 0;
    transition: all 0.3s;
}

.btn-primary:active,
.btn-primary.active,
.btn-primary:active:hover,
.btn-primary:hover,
.btn-primary:focus {
    color: #222222;
    background-color: #0acd94 !important;
    background-color: #0ad79b;
    border: none;
    outline: none;
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
    background-color: #0acd94 !important;
}

.dropdown-toggle.btn-primary {
    color: #fff !important;
    margin-top: 30px;
}

.scroll-top {
    cursor: pointer;
    position: fixed;
    bottom: 100px;
    left: 10px;
    text-transform: uppercase;
    font-family: aller_bd;
    font-size: 22px;
    display: none;
}

.scroll-top .scroll-arrow {
    color: #fff;
    background-color: rgba(10, 215, 155, 0.8);
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    position: relative;
    top: 3px;
}

.scroll-top .scroll-arrow i {
    position: relative;
    top: -4px;
}

.nothing-to-show {
    margin: 0 0 30px;
}


/*-------top buttons---*/

.top-buttons {
    margin: 10px 0 20px 0;
}

.top-buttons:after {
    content: "";
    display: table;
    clear: both;
}

.top-buttons .btn-group {
    width: 271px;
    margin-right: 20px;
}

.top-buttons .btn-group .dropdown .btn {
    text-align: left;
    position: relative;
    border-radius: 0;
}

.top-buttons .btn-group .dropdown .btn .caret {
    position: absolute;
    right: 0px;
    margin-top: 2px;
    width: 32px;
    height: 20px;
    border: none;
    background: url("../images/caret.png") no-repeat 0 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.top-buttons .btn-group .dropdown .dropdown-menu {
    background-color: #4b4b4a;
    margin-top: -2px;
    border-radius: 0;
}

.top-buttons .btn-group .dropdown .dropdown-menu li a {
    color: #fff;
}

.top-buttons .btn-group .dropdown .dropdown-menu li a:hover {
    color: #4b4b4a;
}

.top-buttons .btn-group .dropdown.open .dropdown-menu {
    width: 100%;
}

.top-buttons .btn-group .dropdown.open .btn > .caret {
    -webkit-transform: inherit;
    transform: inherit;
}

.top-buttons .btn-group .btn {
    width: 100%;
}

.top-buttons .pop-up {
    float: right;
    margin-right: 0;
}


/*--------modal--------*/

.modal {
    z-index: 4050;
}

.modal-dialog {
    margin-right: auto;
    margin-left: auto;
    margin-top: 5%;
}

.modal-dialog button.close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 30px;
}

.modal-dialog .modal-content {
    background-color: #0ad79b;
    border-radius: 0;
}

.modal-dialog .modal-content h4 {
    text-transform: uppercase;
    color: #fff;
    font-family: nexa_rust_sansblack;
    font-size: 28px;
    margin: 20px 0;
    padding-left: 15px;
}

.modal-dialog .modal-content .form-horizontal label {
    text-transform: uppercase;
    color: #fff;
    text-align: left;
    font-family: aller_bd;
    font-size: 19px;
    padding-top: 8px;
}

.modal-dialog .modal-content .form-horizontal .form-control {
    border-radius: 0;
    height: 50px;
    font-size: 19px;
    font-family: 'lato', Arial;
    background-color: #fff;
}

.modal-dialog .modal-content .form-horizontal #spellTimeStart,
.modal-dialog .modal-content .form-horizontal #spellTimeEnd {
    display: inline-block;
    width: 45%;
    min-width: 6.5em;
}

.modal-dialog .modal-content .form-horizontal #spellTimeEnd {
    float: right;
}

.modal-dialog .modal-content .form-horizontal .select-wrapper {
    overflow: hidden;
    position: relative;
}

.modal-dialog .modal-content .form-horizontal .select-wrapper select.form-control {
    height: 115px;
}

.modal-dialog .modal-content .form-horizontal .select-wrapper .select-controll {
    position: absolute;
    top: 1px;
    right: 16px;
    bottom: 2px;
    width: 56px;
    background: #fff;
    overflow: hidden;
}

.modal-dialog .modal-content .form-horizontal .select-wrapper .select-controll div {
    cursor: pointer;
}

.modal-dialog .modal-content .form-horizontal .select-wrapper .select-controll .up {
    position: absolute;
    top: 15px;
    right: 12px;
    width: 32px;
    height: 20px;
    background: url("../images/select-controll.png") 0 0 no-repeat;
}

.modal-dialog .modal-content .form-horizontal .select-wrapper .select-controll .down {
    position: absolute;
    bottom: 15px;
    right: 12px;
    width: 32px;
    height: 20px;
    background: url("../images/select-controll.png") 0 0 no-repeat;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.modal-dialog .modal-content .form-horizontal textarea.form-control {
    height: 125px;
    resize: vertical;
}

.modal-dialog .modal-content .form-horizontal .btn {
    margin-top: 70px;
}

#succsessModal p {
    color: #fff;
}


/*--------submenu------*/

.sub-menu {
    margin-top: 10px;
}

.sub-menu ul {
    list-style-type: none;
    background-color: #dadada;
    border-radius: 4px;
}

.sub-menu ul li {
    display: inline-block;
}

.sub-menu ul li a {
    font-family: 'lato', Arial;
    font-size: 18px;
    color: #4b4b4a;
    padding: 20px 10px;
    display: inline-block;
    text-transform: uppercase;
    transition: all 0.5s;
}

.sub-menu ul li a:hover {
    text-decoration: none;
    color: #0ad79b;
}

.sub-menu ul .active {
    background-color: #c7c6c6;
}

.sub-menu ul .active a:hover {
    color: inherit;
}


/*========KLAS---------*/

.denk,
.request-btn {
    color: #fff !important;
    width: 100%;
    text-align: left;
    border-radius: 0;
    background: #0ad79b url("../images/dropdown.png") 96% center no-repeat !important;
    margin-bottom: 20px;
}

.denk:hover,
.denk:active,
.request-btn:hover,
.request-btn:active {
    color: #fff;
}

#denk textarea {
    width: 100%;
    resize: none;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #cccccc;
}

#denk label[for="file-input"] {
    background-color: #dadada;
    position: relative;
    overflow: hidden;
    max-width: 253px;
}

#denk label[for="file-input"] input#file-input {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.wall .thumbnail.record:last-child {
    margin-bottom: 0;
}

.wall .thumbnail.record {
    background: none;
    border: none;
    border-radius: 0;
    margin-bottom: 25px;
    padding: 0;
}

.wall .thumbnail.record .record-info {
    overflow: hidden;
    text-transform: uppercase;
    font-size: 19px;
    background-color: rgba(218, 218, 218, 0.9);
    border-bottom: 1px solid #fff;
}

.wall .thumbnail.record .record-info p {
    margin-left: 130px;
    padding-left: 10px;
}

.wall .thumbnail.record .record-info img {
    float: left;
    background-color: #fff;
    padding-right: 2px;
    width: 130px;
    height: 130px;
}

.wall .thumbnail.record .record-info .record-author {
    margin-left: 130px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

.wall .thumbnail.record .record-info .record-date .day:after {
    content: "|";
    padding-left: 3px;
}

.wall .thumbnail.record .record-content {
    background: rgba(218, 218, 218, 0.9);
    margin-left: 130px;
}

.wall .thumbnail.record .record-content img {
    border: 1px solid rgba(218, 218, 218, 0.9);
    width: 100%;
}

.wall .thumbnail.record .record-content .record-content-text {
    background: #fff;
    padding: 10px;
    margin: 10px;
    text-indent: 10px;
    text-align: justify;
    outline-width: 10px;
    outline-style: solid;
    outline-color: rgba(218, 218, 218, 0.9);
}

.wall .thumbnail.record .record-content p {
    text-align: right;
    margin: 0;
    border-top: 1px solid #fff;
    padding-right: 10px;
}

.wall .thumbnail.record .record-content p a {
    color: #4b4b4a;
    font-family: 'lato', Arial;
    font-size: 19px;
    display: inline-block;
    padding: 10px 0 10px 35px;
}

.wall .thumbnail.record .record-content p a:hover,
.wall .thumbnail.record .record-content p a:active {
    text-decoration: none;
}

.wall .thumbnail.record .record-content p .leuk {
    background: url("../images/thumbs_up.png") 0 5px no-repeat;
    margin-right: 20px;
    position: relative;
    padding-left: 30px;
}

.wall .thumbnail.record .record-content p .leuk .counter {
    font-size: 18px;
    display: inline-block;
    padding: 0 5px;
    text-align: center;
}

.wall .thumbnail.record .record-content p .leuk.active {
    background-image: url("../images/thumbs_up_green.png");
}

.wall .thumbnail.record .record-content p .reactie {
    background: url("../images/spreekwolk.png") 0 13px no-repeat;
}

.wall .thumbnail.record .record-reactie {
    margin-left: 130px;
    padding: 0 10px;
    background: rgba(218, 218, 218, 0.9);
}

.wall .thumbnail.record .record-reactie .add-reacties {
    overflow: hidden;
    display: none;
}

.wall .thumbnail.record .record-reactie .add-reacties .alert-danger {
    display: none;
    border: none;
    background: none;
    margin-bottom: 0;
    padding: 10px 0;
    color: red;
    text-align: right;
}

.wall .thumbnail.record .record-reactie .add-reacties textarea {
    width: 100%;
    resize: none;
    padding: 10px;
}

.wall .thumbnail.record .record-reactie .add-reacties button {
    margin-bottom: 10px;
}

.wall .thumbnail.record .record-reactie .item {
    overflow: hidden;
    position: relative;
    padding-top: 10px;
    border-top: 1px solid #eee;
}

.wall .thumbnail.record .record-reactie .item textarea {
    width: 100%;
    resize: none;
    padding: 10px;
    display: inline-block;
    border: 1px solid rgba(218, 218, 218, 0.9);
    border-right: 0;
}

.wall .thumbnail.record .record-reactie .item .record-author {
    text-transform: uppercase;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
}

.wall .thumbnail.record .record-reactie .item .record-date {
    display: inline-block;
    vertical-align: top;
    margin-left: 15px;
}

.wall .thumbnail.record .record-reactie .item .record-date .day:after {
    content: "|";
    padding-left: 3px;
}

.wall .thumbnail.record .record-reactie .item .delete-comment-reaction {
    position: absolute;
    right: 0;
    top: 5px;
}

.wall .thumbnail.record .record-reactie .item .item-img {
    padding-right: 15px;
    padding-top: 5px;
}

.wall .thumbnail.record .record-reactie .item .item-img img {
    max-width: 100%;
    height: 90px;
}

.wall .thumbnail.record .record-reactie .item .item-body {
    background-color: #fff;
    display: table-cell;
    vertical-align: top;
    overflow: hidden;
    width: 10000px;
    font-family: 'lato', Arial;
    font-size: 19px;
    padding: 10px 10px 10px 10px;
    text-align: justify;
    text-indent: 15px;
}

.wall .thumbnail.record .record-reactie .item .item-reactie {
    margin-top: 10px;
    padding-left: 20%;
    padding-bottom: 10px;
    text-align: right;
}

.wall .thumbnail.record .record-reactie .item .item-reactie .leuk {
    background: url("../images/thumbs_up.png") 0 4px no-repeat;
    margin-right: 20px;
    position: relative;
    padding: 10px 0 10px 30px;
}

.wall .thumbnail.record .record-reactie .item .item-reactie .leuk .counter {
    font-size: 18px;
    display: inline-block;
    padding: 0 5px;
    text-align: center;
}

.wall .thumbnail.record .record-reactie .item .item-reactie .leuk.active {
    background-image: url("../images/thumbs_up_green.png");
}

.wall .thumbnail.record .record-reactie .item .item-reactie .reactie {
    background: url("../images/spreekwolk.png") 0 11px no-repeat;
    padding: 10px 0 10px 35px;
}

.wall .thumbnail.record .record-reactie .item .add-comments {
    overflow: hidden;
    margin-bottom: 10px;
}

.wall .thumbnail.record.longPost .record-content-text {
    height: 9em;
    overflow: hidden;
    transition: height 0.5s;
}

.wall .thumbnail.record.longPost .readMore {
    text-align: right;
    background-color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    color: #0ad79b;
    position: relative;
    top: -10px;
    margin: 0 10px;
}

.wall .thumbnail.record.longPost .readMore span {
    padding-right: 5px;
}

.wall .thumbnail.record.longPost .readMore i {
    font-size: 14px;
}


/*=======request=======*/

.request-item {
    background-color: #dadada;
    padding: 10px;
    margin: 10px 0 20px 0;
}

.request-item .request-author {
    text-transform: capitalize;
    color: #0ad79b;
    font-family: aller_bd;
}

.request-item .request-time {
    color: #969696;
}

.request-item .request-data i {
    margin-right: 10px;
}

.request-item .request-title {
    margin: 10px 0;
    font-family: aller_bd;
    text-align: justify;
    color: #4b4b4a;
}

.request-item .request-text {
    margin: 10px 0;
    text-align: justify;
    background-color: #fff;
    padding: 10px 10px 10px 20px;
}

.request-item .request-comments {
    display: none;
    margin-top: 10px;
}

.request-item .request-comments .request-comment-item {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
}

.request-item .request-comments .request-comment-item ul {
    list-style-type: none;
    padding: 0;
}

.request-item .request-comments .request-comment-item ul li {
    display: inline-block;
    padding-left: 4px;
}

.request-item .request-comments .request-comment-item ul li:first-child {
    border-right: 1px solid #dadada;
    padding-right: 8px;
    padding-left: 0;
}

.request-item .request-comments .request-comment-item ul .request-comment-user {
    text-transform: capitalize;
    color: #0ad79b;
    cursor: pointer;
    font-family: aller_bd;
}

.request-item .request-comments .request-comment-item .request-comment-date {
    display: inline-block;
}

.request-item .request-comments .request-comment-item .request-comment-text {
    margin: 10px 0;
    text-align: justify;
    background-color: #fff;
}

.request-item .request-comments .request-comment-form form {
    text-align: right;
}

.request-item .request-comments .request-comment-form form textarea {
    width: 100%;
    height: 100px;
    resize: vertical;
    text-align: justify;
    padding: 10px;
}

.request-item .request-comments .request-comment-form form button {
    display: inline-block;
    width: 100px;
}

.request-item .request-comments button {
    display: inline-block;
    border: none;
}

.request-item .request-button-group .request-need-people {
    display: block;
    min-height: 25px;
}

.request-item .request-button-group .request-need-people form {
    display: inline-block;
}

.request-item .request-button-group .request-need-people form button {
    display: inline-block;
    border: none;
}

.request-item .request-button-group .request-need-people .request-count {
    color: red;
}

.request-item .request-button-group .btn-pull-right {
    float: right;
}


/*-----aside----------*/

aside h2 {
    font-family: nexa_rust_sansblack;
    font-size: 28px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 12px;
    color: #FFFFFF;
    background-color: rgba(75, 75, 75, 0.8);
}

aside h3 {
    font-family: aller_bd;
}

aside .speel {
    margin-bottom: 30px;
}

aside .speel .speel-item {
    background: #dadada;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 10px;
    position: relative;
}

aside .speel .speel-item .speel-img {
    padding-right: 20px;
}

aside .speel .speel-item .speel-img img {
    border: 1px solid #fcfcfc;
}

aside .speel .speel-item .speel-body {
    display: table-cell;
    position: relative;
    vertical-align: top;
    overflow: hidden;
    width: 100%;
    padding-bottom: 40px;
    font-family: 'lato', Arial;
    font-size: 19px;
}

aside .speel .speel-item .speel-body .speel-time p {
    margin-bottom: 5px;
}

aside .speel .speel-item .speel-body .speel-time .day {
    font-family: aller_bd;
}

aside .speel .speel-item .speel-body a:hover {
    text-decoration: none;
}

aside .speel .speel-item .speel-body .daan {
    font-family: aller_bd;
    color: #0ad79b;
}

aside .speel .speel-item .speel-body .info-link,
aside .speel .speel-item .speel-body .request-item .request-comments button,
.request-item .request-comments aside .speel .speel-item .speel-body button,
aside .speel .speel-item .speel-body .request-item .request-button-group .request-need-people form button,
.request-item .request-button-group .request-need-people form aside .speel .speel-item .speel-body button {
    position: absolute;
    bottom: 0px;
    left: 0;
}

aside .speel .speel-collapse {
    margin-top: -10px;
    background-color: #0ad79b;
    padding: 10px;
    margin-bottom: 10px;
    overflow: hidden;
}

aside .speel .speel-collapse textarea {
    width: 100%;
    height: 100%;
    padding: 0 15px;
    resize: none;
}

aside .speel .speel-collapse .well {
    border-radius: 0;
}

aside .gallery {
    margin-bottom: 30px;
}

aside .gallery img {
    width: 100%;
    padding-bottom: 10px;
}

aside .gallery a {
    padding-top: 16px;
}

aside .gallery .btn {
    width: 50%;
}

aside .gallery:after {
    content: "";
    display: table;
    clear: both;
}

aside .vragen {
    margin-top: 30px;
}

aside .vragen .vragen-item {
    margin-bottom: 20px;
}

aside .vragen .vragen-item .vragen-body {
    background-color: #dadada;
    padding: 10px;
    font-family: 'lato', Arial;
}

aside .vragen .vragen-item .vragen-body .request-title {
    font-family: aller_bd;
    text-align: justify;
    margin-bottom: 10px;
}

aside .vragen .vragen-item .vragen-body .request-date {
    margin-bottom: 10px;
}

aside .vragen .vragen-item .vragen-body .request-date i {
    margin-right: 10px;
}

aside .vragen .vragen-item .vragen-body .request-text {
    padding: 10px;
    background-color: #fff;
    text-align: justify;
}

aside .vragen .vragen-item .vragen-body .vragen-reactie {
    margin-top: 10px;
}

aside .vragen .vragen-item .vragen-body .vragen-reactie .people-need-container {
    margin-bottom: 10px;
}

aside .vragen .vragen-item .vragen-body .vragen-reactie .request-count {
    color: red;
}

aside .vragen .vragen-item .vragen-body .vragen-reactie form {
    display: inline-block;
}

aside .vragen .vragen-item .vragen-body .vragen-reactie form button {
    border: none;
}

aside .vragen .vragen-item .vragen-info {
    font-family: 'lato', Arial;
    margin-top: 15px;
}

aside .vragen .vragen-item .vragen-info .klasse span {
    color: #0ad79b;
    display: inline-block;
    padding-right: 5px;
}


/*=========PROFIEL============*/

.spelen-item {
    background: #dadada;
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
}

.spelen-item .spelen-reacties {
    margin-top: 20px;
}

.spelen-item .spelen-reacties {
    display: none;
}

.spelen-item .spelen-reacties.open {
    display: block;
}

.spelen-item .spelen-person {
    padding-right: 25px;
}

.spelen-item .spelen-person img {
    max-width: 100%;
    height: 125px;
}

.spelen-item .spelen-person .spelen-name {
    color: #0ad79b;
    font-family: aller_bd;
    margin-top: 10px;
}

.spelen-item .spelen-body {
    display: table-cell;
    vertical-align: top;
    overflow: hidden;
    width: 100%;
    font-family: 'lato', Arial;
    font-size: 19px;
    position: relative;
    top: -5px;
}

.spelen-item .spelen-data span:first-child {
    display: inline-block;
    width: 125px;
}

.spelen-item .spelen-data.canceled {
    margin-top: 20px;
    color: #FF0000;
}

.spelen-item hr {
    color: #c4c3c3;
}

.spelen-item .spelen-reacties .reacties-item {
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
}

.spelen-item .spelen-reacties .reacties-item .reacties-info {
    position: relative;
}

.spelen-item .spelen-reacties .reacties-item .reacties-info ul {
    padding: 0;
    list-style-type: none;
}

.spelen-item .spelen-reacties .reacties-item .reacties-info ul li {
    display: inline-block;
    border-right: 1px solid #dadada;
    padding-right: 8px;
}

.spelen-item .spelen-reacties .reacties-item .reacties-info ul li:last-child {
    border: none;
}

.spelen-item .spelen-reacties .reacties-item .reacties-info ul li .reacties-name {
    color: #0ad79b;
    font-family: aller_bd;
}

.spelen-item .spelen-reacties form {
    display: inline-block;
}

.spelen-item .spelen-reacties .comment-form {
    width: 100%;
}

.spelen-item textarea {
    width: 100%;
    padding: 15px;
    resize: none;
    height: 140px;
    border: none;
}

form.pull-right.delete-form.delete-game {
    position: absolute;
    right: 15px;
}


/*=========CONTACT============*/

.contact-form-header {
    margin: 40px 0 50px 0;
}

#tabs-profiel-data .nav.nav-tabs {
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
}

#tabs-profiel-data .nav.nav-tabs li a {
    border: none;
    background: none;
    color: #7e7e7d;
}

#tabs-profiel-data .nav.nav-tabs li a h2 {
    font-size: 22px;
}

#tabs-profiel-data .nav.nav-tabs li.active a {
    color: #4b4b4a;
}

#tabs-profiel-data .tab-content .tab-pane .form-horizontal label {
    text-align: right;
}

#tabs-profiel-data .tab-content .tab-pane .form-horizontal .form-group label[for="file-input"] {
    background-color: #dadada;
    position: relative;
    overflow: hidden;
    width: 270px;
    max-width: 253px;
    text-align: center;
    cursor: pointer;
}

#tabs-profiel-data .tab-content .tab-pane .form-horizontal .form-group label[for="file-input"] input#file-input {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.toevoegen {
    background: url("../images/add.png") left center no-repeat;
    padding: 15px 0 15px 60px;
    display: inline-block;
    border: none;
    outline: none;
    text-transform: uppercase;
}


/*=========klasseouder=========*/

.klasseouder {
    text-transform: uppercase;
}

.klasseouder .btn {
    padding: 10px 15px;
    margin: 10px 0;
}

.klasseouder .title input {
    height: 40px;
    border-radius: 0;
    font-size: 18px;
    border: 1px solid darkgray;
}

.klasseouder .date .scheduledDate {
    margin-top: 0;
    display: none;
}

.klasseouder .date .scheduledDate #scheduledDate {
    border: 1px solid #ccc;
    border-radius: 0;
    font-size: 18px;
    background-color: rgba(238, 238, 238, 0.5);
    padding-left: 10px;
}

.klasseouder textarea {
    width: 100%;
    height: 200px;
    padding: 10px;
    text-transform: none;
    resize: none;
    overflow: auto;
}

.klasseouder textarea:focus {
    outline: none;
}

.klasseouder label[for="file-input"] {
    background-color: #dadada;
    position: relative;
    overflow: hidden;
}

.klasseouder label[for="file-input"] input#file-input {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.klasseouder .checkbox {
    position: relative;
}

.klasseouder .checkbox input[type=checkbox]:checked + label:before {
    content: "\2713";
    color: #4b4b4a;
    text-align: center;
}

.klasseouder .checkbox input {
    display: none;
}

.klasseouder .checkbox label {
    padding-left: 0;
}

.klasseouder .checkbox label:before {
    content: "\2713";
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #dadada;
    border-radius: 4px;
    background-color: #dadada;
    position: relative;
    top: 1px;
    margin-right: 10px;
    box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 0px 0px rgba(255, 255, 255, 0.8);
}

.klasseouder .number {
    position: relative;
    margin: 15px 0 0 0;
    display: none;
}

.klasseouder .number input {
    background-color: #dadada;
    border-radius: 4px;
    box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 0px 0px rgba(255, 255, 255, 0.8);
    border: none;
    width: 50px;
    height: 20px;
    text-align: center;
    margin-right: 10px;
    position: relative;
    padding-left: 10px;
    top: -1px;
}

.klasseouder .number label {
    font-family: 'lato', Arial;
    font-weight: normal;
}

.klasseouders-teach {
    font-size: 19px;
    font-family: aller_bd;
    text-transform: uppercase;
    padding-right: 35px;
}

.vragen-plaats {
    text-transform: uppercase;
    font-family: aller_bd;
    margin-bottom: 20px;
}

.vragen-nodig {
    margin: 15px 0;
    color: #878787;
}

.vragen-nodig span:first-child {
    font-family: aller_bd;
    color: #4b4b4a;
}

.vragen-nodig .vragen-nam {
    color: #fb0505;
}

.vragen-item.active .link,
.vragen-item.active .wall .thumbnail.record .record-reactie .item .item-reactie a,
.wall .thumbnail.record .record-reactie .item .item-reactie .vragen-item.active a,
.vragen-item.active .toevoegen {
    background-color: #fff;
    border-radius: 4px;
    margin-left: 10px;
    padding: 3px 15px;
}


/*=========Foto===========*/

.main.foto .evenement-plaats {
    font-family: nexa_rust_sansblack;
    font-size: 28px;
    text-transform: uppercase;
    margin: 50px 0 25px 0;
}

.gallery-item {
    color: #4b4b4a;
    text-decoration: none;
}

.gallery-item:hover {
    color: #4b4b4a;
}

.gallery .col-md-2 {
    padding: 0;
}

.gallery img {
    width: 100%;
}


/*=============Album ===========*/

.album-page .alert {
    display: none;
}

.album-page h2 {
    margin: 20px 0 10px 0;
}

.album-page .album-description {
    margin: 0 0 20px 0;
}

.album-page .thumbnail-wrapper {
    overflow: hidden;
    margin: 0 -5px;
}

.album-page .thumb {
    padding: 0px;
    overflow: hidden;
}

.album-page .thumb .thumbnail {
    border-radius: 0;
    border: none;
    margin: 5px;
    padding: 0;
}

.album-page .thumb:hover {
    z-index: 10;
}

.album-page .thumb .fancyimage .img-responsive {
    opacity: 0.9;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 0px;
    width: 100%;
    transition: all 0.2s linear;
}

.album-page .thumb .fancyimage .img-responsive:hover {
    opacity: 1;
}

.album-page .album-comments {
    margin-top: 5px;
    overflow: hidden;
}

.album-page .album-comments .list-group-item {
    border-radius: 0;
    margin-bottom: 10px;
    padding: 10px;
    background-color: rgba(218, 218, 218, 0.9);
}

.album-page .album-comments .list-group-item .reacties-item {
    position: relative;
}

.album-page .album-comments .list-group-item .reacties-text {
    background-color: #fff;
    padding: 10px;
    margin-top: 10px;
}

.album-page .album-comments .list-group-item .reacties-info .reacties-author {
    font-family: aller_bd;
    text-align: justify;
    margin-bottom: 10px;
}

.album-page .album-comment-form textarea {
    resize: vertical;
    border-radius: 0;
}

.album-page #editForm {
    position: relative;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-bottom: none;
}

.album-page #editForm textarea {
    height: 5em;
}

.album-page #editForm .btn {
    width: 100%;
    height: 40px;
    padding: 0;
    margin: 0;
}

.album-page #createPhotoForm {
    display: none;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    border: 1px solid #ccc;
    padding: 10px 0;
}

.album-page #createPhotoForm label {
    font-family: 'lato', Arial;
    width: 100%;
    border-bottom: 1px solid #ccc;
    text-align: left;
    color: #4b4b4a;
    font-weight: normal;
    padding: 0px 10px 10px 10px;
}

.album-page #createPhotoForm .btn {
    position: absolute;
    bottom: -40px;
    left: 0;
}


/*=============Footer==========*/

footer hr {
    color: #dadada;
    height: 1px;
}

footer .info {
    padding-bottom: 50px;
    padding-top: 60px;
}

footer .info .col-md-4 {
    text-align: center;
}

footer .info .col-md-4 .caption {
    font-size: 18px;
    font-family: aller_bd;
    text-transform: uppercase;
}

footer .info .col-md-4 p {
    font-family: 'lato', Arial;
}

footer .info .col-md-4 a {
    width: 100%;
    display: inline-block;
}

footer .info .col-md-4 img {
    margin: 20px 0 10px 0;
}

footer .copyright {
    padding-top: 40px;
    padding-bottom: 35px;
}

footer .copyright a {
    display: inline;
    font-family: 'lato', Arial;
}

.new-recreation #toggleAll {
    color: #0ad79b;
}

.new-recreation .form-horizontal {
    margin-top: 20px;
}

.new-recreation .form-horizontal .form-group label {
    text-align: right;
    text-transform: uppercase;
}

.new-recreation .form-horizontal .form-group label[for="speelParticipants"] {
    margin-bottom: 10px;
}

.new-recreation .form-horizontal .form-group textarea {
    height: auto;
}

ul.thumbnails.image_picker_selector li {
    width: 20%;
    display: inline-block;
    float: none !important;
    padding: 0 12px 12px 0;
    text-align: center;
    margin: 0 !important;
}

ul.thumbnails.image_picker_selector li .thumbnail {
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
}

ul.thumbnails.image_picker_selector li .thumbnail.selected {
    background-color: rgba(10, 215, 155, 0.8) !important;
}


/*album*/

.album-page .top-buttons .btn-group.btn-create-album,
.album-page .top-buttons .btn-group.btn-back-album {
    width: auto;
    max-width: 320px;
}

.album-page .album-wrapper {
    margin-top: 20px;
}

.album-page .album-wrapper .thumbnail {
    background: rgba(218, 218, 218, 0.9);
    border-radius: 0;
    padding: 0;
    border: none;
}

.album-page .album-wrapper .thumbnail img {
    width: 100%;
}

.album-page .album-wrapper .thumbnail .caption {
    padding: 0;
}

.album-page .album-wrapper .thumbnail .caption h3 {
    font-family: aller_bd;
    text-align: justify;
    margin: 10px;
    height: 2.4em;
    overflow: hidden;
}

.album-page .album-wrapper .thumbnail .caption p {
    font-size: 19px;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    margin: 0 1px;
    text-align: justify;
    height: 5em;
    overflow: hidden;
}

.album-page .album-wrapper .thumbnail .caption .btn.btn-block.btn-primary {
    border-radius: 0;
}

.album-page #createAlbumForm {
    padding: 20px 0 0 0;
    background: rgba(255, 255, 255, 0.6);
}

.album-page #createAlbumForm button.btn.btn-block {
    height: 40px;
    border: none;
    border-radius: 0;
}

.album-page #createAlbumForm textarea {
    height: 6em !important;
    resize: vertical;
    background-color: rgba(238, 238, 238, 0.5);
    font-size: 18px;
}

.album-page #createAlbumForm label[for="file"] {
    display: block;
    font-family: 'lato', Arial;
    font-weight: normal;
    border-bottom: 1px solid #ccc;
}

.list-group.student-list-data form input:disabled,
.list-group.student-list-data form select:disabled > option {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}

.list-group.student-list-data form select:disabled,
.list-group.student-list-data form .birthday:disabled,
.list-group.student-list-data form .form-control[disabled] {
    cursor: inherit;
    background-color: rgba(255, 255, 255, 0) !important;
    border: none;
    outline: none;
    box-shadow: none;
    color: #4b4b4a;
    /*for WebKit*/
    -webkit-appearance: none;
    /* for FF */
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    /* for IE */
    -ms-appearance: none;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}

.list-group.student-list-data form input,
.list-group.student-list-data form select {
    padding: 0;
    font-size: 18px;
}

.list-group.student-list-data form .list-group-item-text {
    height: 50px;
}

.list-group.student-list-data form .list-group-item-heading {
    font-family: aller_bd;
    margin-bottom: 0px;
    height: 50px;
    padding: 10px 15px;
}

.list-group.student-list-data form .list-group-item {
    background-color: rgba(238, 238, 238, 0.8);
    border-radius: 0px;
    padding: 0px 0px 0px 10px;
    margin: 0;
}

.list-group.student-list-data form .student-status {
    line-height: 50px;
}

.list-group.student-list-data .edit-btn-group {
    margin-top: 10px;
    text-align: right;
    padding-right: 0;
}

.list-group.student-list-data .edit-btn-group .cancel {
    display: none;
}

.list-group.student-list-data .edit-btn-group .btn {
    width: 100%;
}

.list-group.student-list-data .save-student-info {
    display: none;
    padding-left: 0;
}

.list-group.student-list-data .save-student-info button {
    margin-top: 10px;
    width: 100%;
}

.list-group.student-list-data .deactive-student {
    display: none;
    padding-right: 0;
    padding-left: 0;
}

.list-group.student-list-data .deactive-student form {
    margin-top: 10px;
}

.list-group.student-list-data .deactive-student form .btn {
    width: 100%;
}

.list-group.student-list-data form input,
.list-group.student-list-data form select {
    padding: 0;
    font-size: 18px;
    width: 100%;
    height: 48px;
    border-radius: 0;
    border: none;
}

.list-group.student-list-data form select {
    height: 46px;
    position: relative;
}

.student-info-container .student-avatar {
    text-align: right;
}

.student-info-container .student-avatar .avatar-view {
    display: inline-block;
    border: 1px solid #ddd;
    margin: 20px 0 0 0;
}

#avatar-modal {
    margin-top: 100px;
}

#avatar-modal form#avatar-form {
    text-align: left;
}

#avatar-modal form#avatar-form .avatar-upload input {
    float: none;
}

.student-save-errors {
    display: none;
}

.btn-danger {
    background-color: #FF4136;
    color: #fff;
    border: none;
    border-radius: 0;
    transition: all 0.2s;
}

.teacher-page .list-group.student-list-data .list-group-item {
    height: auto;
}

.teacher-page .list-group.student-list-data .list-group-item .list-group-item-heading {
    margin-top: -5px;
}

.teacher-page .get-student-list {
    margin: 20px 0;
}

.empty-teacher-pages {
    padding-top: 30px;
}

.empty-teacher-pages h2 {
    margin-bottom: 30px;
}

.fancy-likes {
    display: inline-block;
    padding: 10px 10px 10px 40px;
    background: url("../images/thumbs_up.png") 0 5px no-repeat;
    color: #FFFFFF !important;
    display: inline-block;
    margin-left: 10px;
    transition: all 0.3s;
}

.fancy-likes:hover,
.fancy-likes.active {
    background-image: url("../images/thumbs_up_green.png");
    color: #fff;
}

.fancy-delete {
    float: right;
    margin-right: 10px;
    font-size: 26px;
    transition: all 0.3s;
}

.fancy-delete i {
    color: #FFFFFF;
}

.fancy-delete:hover {
    cursor: pointer;
}

.fancy-delete:hover i {
    color: red;
}

.fancybox-margin.navbar-fixed-top {
    margin: 0 auto !important;
    left: -17px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .wall .thumbnail.record .record-reactie .item .record-date {
        display: block;
        margin-left: 0;
    }
    aside h2 {
        margin-top: 17px;
        margin-bottom: 30px;
    }
    aside .speel .speel-item .speel-img {
        padding-bottom: 10px;
    }
    aside .speel .speel-item .speel-body {
        display: block;
        padding-bottom: 0;
    }
    aside .speel .speel-item .speel-body .speel-time {
        margin-bottom: 0px;
        text-align: center;
    }
    aside .speel .speel-item .speel-body .speel-time p {
        margin: 0;
    }
    aside .speel .speel-item .speel-body .daan {
        display: block;
    }
    aside .speel .speel-item .speel-body .info-link,
    aside .speel .speel-item .speel-body .request-item .request-comments button,
    .request-item .request-comments aside .speel .speel-item .speel-body button,
    aside .speel .speel-item .speel-body .request-item .request-button-group .request-need-people form button,
    .request-item .request-button-group .request-need-people form aside .speel .speel-item .speel-body button {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    body {
        /* background: url("../images/container-image.png") -170px 340px no-repeat;*/
        margin-bottom: 20px;
        background-size: initial;
    }
    .header-wrapper img {
        min-height: 300px;
    }
    section.main {
        min-height: 1480px;
    }
}

@media (min-width: 768px) {
    header {
        margin-bottom: -90px;
    }
    .sub-menu-toggle {
        display: none;
    }
    #tabs-profiel-data .nav.nav-tabs {
        display: inline-block;
        border-bottom: 1px solid #ddd;
    }
    #tabs-profiel-data .nav.nav-tabs li {
        overflow: hidden;
        border: 1px solid #ddd;
        margin-bottom: -1px;
        margin-left: -3px;
        z-index: 10;
        background-color: rgba(238, 238, 238, 0.8);
        border-top-left-radius: 10px;
        -webkit-border-top-left-radius: 10px;
        -moz-border-radius-topleft: 10px;
        border-top-right-radius: 10px;
        -webkit-border-top-right-radius: 10px;
        -moz-border-radius-topright: 10px;
    }
    #tabs-profiel-data .nav.nav-tabs li a h2 {
        margin: 5px 0;
    }
    #tabs-profiel-data .nav.nav-tabs li.active {
        background-color: #fff;
        border: 1px solid rgba(238, 238, 238, 0.8);
    }
    #tabs-profiel-data .nav.nav-tabs li.active a {
        color: #4b4b4a;
    }
}

@media (min-width: 480px) {
    .record-author {
        position: relative;
    }
}

@media (max-width: 1199px) {
    .header-wrapper .header-text {
        left: 50%;
        right: 15%;
        margin-bottom: 25px;
    }
    .top-buttons .btn-group {
        width: 250px;
    }
    #tabs-profiel-data .nav.nav-tabs li a {
        padding: 10px;
    }
}

@media (max-width: 991px) {
    #tabs-profiel-data .form-group {
        margin-bottom: 0;
    }
    #tabs-profiel-data .form-horizontal button[type="submit"] {
        margin-top: 18px;
    }
    #tabs-profiel-data .form-horizontal .control-label {
        padding-top: 8px;
    }
    .student-item {
        margin-top: 20px;
    }
    .list-group.student-list-data {
        margin: 20px 0;
    }
    .list-group.student-list-data form {
        margin-top: 0;
    }
    .delete-button-class,
    .request-comment-item .delete-request-comment button,
    .reacties-item .delete-comment-request button,
    .request-item .delete-request button,
    .thumbnail.record .remove-post button,
    .thumbnail.record .item.comment .delete-comment-reaction button,
    .spelen-item .delete-game button {
        opacity: 1 !important;
    }
    .avatar-preview.avatar-preview {
        width: 100%;
        text-align: center;
        background: transparent;
        border: none;
    }
    .avatar-preview.avatar-preview img {
        width: 130px;
    }
    .student-info-container .student-avatar {
        text-align: center;
    }
    .student-info-container .student-avatar .avatar-view {
        margin: 0;
    }
    .teacher-btn-group .btn {
        margin-top: 10px;
    }
    .teacher-page .student-avatar {
        text-align: center;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar-wrapper {
        margin-top: 0;
    }
    .navbar-wrapper .container {
        padding: 0;
        margin: auto;
        width: 100%;
        overflow: hidden;
    }
    .navbar-wrapper .container .navbar-default {
        border-radius: 0;
        margin-bottom: 0;
    }
    .navbar-wrapper .container .navbar-default .navbar-header {
        float: none;
    }
    .navbar-wrapper .container .navbar-default .navbar-brand {
        width: 100%;
        text-align: center;
    }
    .navbar-wrapper .container .navbar-default .navbar-brand img {
        display: inline-block;
    }
    .header-wrapper {
        margin-top: 200px;
    }
    .sub-menu ul {
        padding: 0 5px;
        text-align: center;
    }
    .top-buttons .btn-group {
        margin-right: 12px;
    }
    .top-buttons .btn-group.pop-up {
        width: 100%;
        display: block;
        margin: 10px auto;
    }
    #tabs-profiel-data .nav.nav-tabs li a {
        padding: 5px;
    }
    #tabs-profiel-data .nav.nav-tabs li a h2 {
        font-size: 21px;
    }
    aside {
        margin-bottom: 5px;
    }
}

@media (max-width: 767px) {
    #tabs-profiel-data .nav.nav-tabs {
        margin: 20px 0;
    }
    #tabs-profiel-data .nav.nav-tabs li {
        display: inline-block;
        width: 100%;
        text-align: center;
        background: rgba(238, 238, 238, 0.8);
    }
    #tabs-profiel-data .nav.nav-tabs li a {
        padding: 0;
    }
    #tabs-profiel-data .nav.nav-tabs li a h2 {
        margin: 5px 0;
    }
    #tabs-profiel-data .nav.nav-tabs li.active {
        background-color: #fff;
        border: 1px solid rgba(238, 238, 238, 0.8);
    }
    #tabs-profiel-data .nav.nav-tabs li.active a {
        color: #4b4b4a;
    }
    .navbar-brand {
        width: auto;
    }
    .header-wrapper {
        margin-top: 65px;
    }
    .header-wrapper .header-text {
        left: 15%;
        right: 15%;
        text-align: center;
        margin-bottom: 15px;
    }
    .top-buttons {
        margin: 0px 0;
    }
    .top-buttons .btn-group {
        text-align: center;
        margin: 10px auto;
        clear: both;
        display: block;
    }
    .sub-menu {
        margin-top: 0;
    }
    .sub-menu ul {
        padding: 0;
    }
    .sub-menu ul li {
        padding: 0;
        width: 100%;
        text-align: center;
    }
    .sub-menu ul li a {
        padding: 10px;
    }
    .copyright {
        padding: 40px 15px 35px 15px;
    }
    .scroll-top {
        display: none !important;
    }
    .modal {
        z-index: 6000;
    }
    /*mobile header like fb*/
    header {
        height: 65px;
        background-color: #222222;
    }
    header:after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 65px;
        background-color: #222222;
        z-index: 100;
    }
    section.main {
        overflow: visible;
    }
    .navbar-wrapper .navbar.navbar-default {
        position: fixed;
        left: -90%;
        right: 10%;
        width: 90%;
        height: 100%;
        transition: all 0.5s;
    }
    .navbar-wrapper .navbar.navbar-default .button-wrapper {
        position: absolute;
        right: -10%;
        width: 10%;
        background: #222222;
        height: 65px;
        z-index: 3000;
    }
    .navbar-wrapper .navbar.navbar-default .button-wrapper .navbar-toggle {
        margin: 12px 0 12px 15px;
        padding-left: 0;
        padding-right: 0;
        width: 40px;
        border-radius: 0;
        position: absolute;
        height: 35px;
    }
    .navbar-wrapper .navbar.navbar-default .button-wrapper .navbar-toggle .icon-bar {
        background-color: #FFFFFF;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        width: 25px;
        transition: all 0.5s;
    }
    .navbar-wrapper .navbar.navbar-default .button-wrapper .navbar-toggle.openMenu .top-icon {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 3px;
    }
    .navbar-wrapper .navbar.navbar-default .button-wrapper .navbar-toggle.openMenu .middle-icon {
        display: none;
    }
    .navbar-wrapper .navbar.navbar-default .button-wrapper .navbar-toggle.openMenu .bottom-icon {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        top: -3px;
    }
    .activeMenu {
        overflow: hidden;
    }
    .activeMenu .bg-menu {
        display: block;
    }
    .bg-menu {
        position: fixed;
        background: rgba(0, 0, 0, 0.5);
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 4000;
        display: none;
        transition: all 0.3s;
    }
    .navbar-wrapper .navbar.navbar-default .navbar-collapse {
        border-top: none;
        box-shadow: none;
    }
    .header-wrapper {
        display: none;
    }
    .sub-menu {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 1000;
    }
    .sub-menu .sub-menu-toggle {
        display: block;
        text-align: center;
        background-color: #222222;
        height: 65px;
        line-height: 65px;
        color: #fff;
    }
    .sub-menu .sub-menu-toggle span {
        display: inline-block;
        width: 16px;
        height: 13px;
        background: url("../images/arrow-down.png") center center no-repeat;
        margin-left: 10px;
        background-size: contain;
    }
    .sub-menu .sub-menu-toggle.open span {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    .sub-menu .sub-menu-toggle + ul {
        display: none;
        position: absolute;
        z-index: 1000;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 1px solid #4b4b4a;
        left: 15px;
        right: 15px;
    }
    .sub-menu .sub-menu-toggle.open + ul {
        display: block;
    }
    .page-content {
        position: static;
    }
    .page-content .denk {
        position: fixed;
        top: 10px;
        right: 15px;
        width: 37px;
        height: 37px;
        z-index: 2000;
        background: #222 url("../images/dropdown.png") center center no-repeat !important;
        border: 1px solid #ddd;
    }
    .page-content .denk span {
        display: none;
    }
    .page-content #denk {
        position: fixed;
        top: 65px;
        right: 15px;
        left: 15px;
        z-index: 1000;
    }
    button.request-btn.btn.btn-primary.btn-lg {
        margin-top: 10px;
    }
    .klasseouder {
        text-align: right;
    }
    .klasseouder .number {
        margin: 0;
    }
    .wall .thumbnail.record .record-content,
    .wall .thumbnail.record .record-reactie {
        margin-left: 0;
    }
    .avatar-upload input {
        display: block !important;
        float: none !important;
    }
    .top-buttons .btn-group {
        width: 100%;
        padding: 0;
        margin: 10px auto;
    }
    ul.thumbnails.image_picker_selector li {
        width: 50%;
    }
    .spelen-item .spelen-person img {
        height: 90px;
    }
    .spelen-item .spelen-person .spelen-name {
        position: absolute;
        top: -40px;
    }
}

@media (min-width: 479px) and (max-width: 767px) {
    .spelen-item {
        padding-top: 30px;
    }
}

@media (max-width: 479px) {
    .spelen-item .spelen-person {
        padding-bottom: 10px;
    }
    .spelen-item .spelen-person img {
        height: 125px;
    }
    .spelen-item .spelen-person .spelen-name {
        position: relative;
        top: auto;
    }
    .spelen-item .spelen-body {
        display: block;
        margin-top: 10px;
    }
    .hidden-sm {
        display: none !important;
    }
    .header-wrapper .header-text {
        left: 5%;
        right: 5%;
        margin-bottom: 10px;
    }
    footer .copyright {
        text-align: center;
    }
    footer .copyright a {
        width: 100%;
    }
    .modal-dialog .form-group {
        margin-bottom: 0;
    }
    .modal-dialog .modal-content .form-horizontal .btn {
        margin-top: 30px;
    }
    .record-reactie .item .record-date {
        margin-left: 0;
    }
    .navbar-wrapper .navbar.navbar-default .button-wrapper .navbar-toggle .icon-bar {
        margin-left: auto;
        margin-right: auto;
    }
    .navbar-wrapper .navbar.navbar-default .button-wrapper .navbar-toggle.openMenu {
        right: 0;
    }
    .navbar-wrapper .navbar.navbar-default .navbar-collapse {
        border-top: none;
        box-shadow: none;
    }
    .page-content #denk .well {
        overflow: hidden;
        padding: 10px;
    }
    .page-content #denk .well label {
        float: right;
    }
    .page-content #denk .well button {
        margin-top: 10px;
        width: 100%;
    }
    .wall .thumbnail.record .record-info img {
        height: 90px;
        width: 90px;
    }
    .wall .thumbnail.record .record-info p {
        margin-left: 90px;
        padding-left: 5px;
    }
    .wall .thumbnail.record .record-info .record-author {
        margin-left: 90px;
        padding-left: 5px;
    }
    .wall .thumbnail.record .record-content p,
    .wall .thumbnail.record .record-reactie .item .item-reactie {
        padding-left: 0;
        padding-right: 0px;
    }
    .wall .thumbnail.record .record-content p .leuk {
        margin-right: 0;
    }
    .wall .thumbnail.record .record-reactie .item .delete-comment-reaction {
        top: auto;
        right: auto;
        bottom: 5px;
        left: 0px;
    }
    .wall .thumbnail.record .record-reactie .item .item-reactie .leuk {
        margin-right: 0px;
    }
    .wall .thumbnail.record .record-reactie .item .item-reactie .reactie {
        margin-right: 0px;
    }
    .wall .thumbnail.record .record-content p a {
        margin-right: 10px;
    }
    .wall .thumbnail.record .record-reactie .item .record-date {
        margin-left: 0;
    }
    .wall .thumbnail.record .record-reactie .item .record-author {
        margin-top: 5px;
        margin-bottom: 0;
    }
    button.request-btn.btn.btn-primary.btn-lg {
        white-space: normal;
    }
    .wall .thumbnail.record .record-reactie .item .record-author {
        display: block;
    }
    .wall .thumbnail.record .record-reactie .item .item-img img {
        display: none;
    }
    .wall .thumbnail.record .record-reactie .add-reacties .btn {
        width: 100%;
        padding: 10px 0;
    }
    .list-group.student-list-data form .list-group-item {
        height: 100px;
        padding: 0px 15px;
    }
    .list-group.student-list-data form .list-group-item .list-group-item-heading {
        width: 100%;
        float: none;
        border-bottom: 1px solid #ddd;
    }
    .list-group.student-list-data form .list-group-item .list-group-item-text {
        width: 100%;
        float: none;
        background: #FFFFFF;
        height: 48px;
    }
    .error-page h4 {
        font-size: 100px;
    }
    .error-page .error-text {
        font-size: 25px;
    }
    .request-button-group {
        text-align: center;
    }
    .request-button-group .pull-left {
        float: none !important;
        margin-bottom: 10px;
    }
    .request-item .request-button-group .btn-pull-right {
        float: none;
        margin-top: 10px;
    }
    .wall .thumbnail.record .thumbnail-top {
        position: relative;
    }
    .wall .thumbnail.record .record-author .remove-post {
        float: none !important;
        position: absolute;
        left: 10px;
        bottom: 10px;
    }
    ul.thumbnails.image_picker_selector li {
        width: 100%;
        padding-right: 0;
    }
    .spelen-item .spelen-reacties form,
    .spelen-item .spelen-reacties.open .text-right .info-link,
    .spelen-item .spelen-reacties.open .text-right .request-item .request-comments button,
    .request-item .request-comments .spelen-item .spelen-reacties.open .text-right button,
    .spelen-item .spelen-reacties.open .text-right .request-item .request-button-group .request-need-people form button,
    .request-item .request-button-group .request-need-people form .spelen-item .spelen-reacties.open .text-right button {
        display: block;
        margin: 5px 0 0 0;
        width: 100%;
        float: left;
    }
    .spelen-item .spelen-reacties.open .reacties-item .delete-comment-request {
        display: inline-block;
        float: none;
        width: 30px;
        margin-top: 0;
    }
    .spelen-item .spelen-reacties .reacties-item .reacties-info ul {
        border-bottom: 1px solid #cccccc;
    }
    .spelen-item .spelen-reacties .reacties-item .reacties-info ul li {
        border: none;
    }
    .spelen-item .spelen-reacties .reacties-item .reacties-info ul li:first-child {
        display: block;
    }
    .youAccept {
        text-align: center;
        margin-top: 5px;
        clear: both;
    }
    .request-item .request-time {
        display: block;
    }
}

@media (max-width: 380px) {
    .spelen-item .spelen-reacties.open .text-right {
        text-align: center;
    }
    .spelen-item .spelen-reacties.open .text-right .info-link,
    .spelen-item .spelen-reacties.open .text-right .request-item .request-comments button,
    .request-item .request-comments .spelen-item .spelen-reacties.open .text-right button,
    .spelen-item .spelen-reacties.open .text-right .request-item .request-button-group .request-need-people form button,
    .request-item .request-button-group .request-need-people form .spelen-item .spelen-reacties.open .text-right button {
        width: 100%;
        display: block;
        text-align: center;
        margin-bottom: 5px;
    }
    .spelen-item .spelen-reacties.open .text-right .info-link.pull-left,
    .spelen-item .spelen-reacties.open .text-right .request-item .request-comments button.pull-left,
    .request-item .request-comments .spelen-item .spelen-reacties.open .text-right button.pull-left,
    .spelen-item .spelen-reacties.open .text-right .request-item .request-button-group .request-need-people form button.pull-left,
    .request-item .request-button-group .request-need-people form .spelen-item .spelen-reacties.open .text-right button.pull-left {
        float: none !important;
    }
    .wall .thumbnail.record .record-reactie .item .item-body {
        width: 100%;
        display: block;
        clear: both;
    }
    .klasseouder .btn {
        width: auto;
    }
    .spelen-item .spelen-data span:last-child {
        display: block;
        width: 100%;
        padding: 5px 0;
    }
    .spelen-item .spelen-data span:first-child {
        display: block;
        width: 100%;
        padding: 5px 0;
        border-bottom: 1px solid #FFFFFF;
    }
}


/*# sourceMappingURL=style.css.map */

.text-center {
    text-align: center !important;
}

#inlogen .nav.nav-tabs {
    border-bottom: 1px solid #ddd;
    font-size: 40px;
    margin: 20px 0 40px 0;
}

#inlogen .nav.nav-tabs li {
    overflow: hidden;
    border: 1px solid #ddd;
    margin-bottom: -1px;
    margin-right: -5px;
    z-index: 10;
    background-color: rgba(238, 238, 238, 0.8);
    border-top-left-radius: 10px;
    -webkit-border-top-left-radius: 10px;
    -moz-border-radius-topleft: 10px;
    border-top-right-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topright: 10px;
}

#inlogen .nav.nav-tabs li a {
    font-family: "lato";
    color: #7e7e7d;
    margin: 0;
    padding: 5px 25px;
    font-size: 25px;
}

#inlogen .nav.nav-tabs li.active {
    z-index: 20;
}

#inlogen .nav.nav-tabs li.active a {
    color: #4b4b4a;
    background-color: #fff;
}

#inlogen .tab-content .tab-pane .checkbox {
    position: relative;
    height: 40px;
    display: inline-block;
}

#inlogen .tab-content .tab-pane .checkbox label {
    padding-bottom: 7px;
    padding-top: 7px;
    padding-left: 40px;
    font-size: 20px;
}

#inlogen .tab-content .tab-pane .checkbox input[type=checkbox]:checked + label:before {
    content: "\2713";
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    color: #4b4b4a;
    text-align: center;
    line-height: 25px;
}

#inlogen .tab-content .tab-pane .checkbox input {
    display: none;
}

#inlogen .tab-content .tab-pane .checkbox label:before {
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    line-height: 30px;
    position: absolute;
    left: 0;
    border-radius: 4px;
    background-color: #dadada;
    box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 0px 0px rgba(255, 255, 255, 0.8);
}

#inlogen .tab-content .tab-pane form label {
    font-family: "lato";
    color: #4b4b4a;
    font-weight: normal;
    padding-top: 12px;
    font-size: 18px;
}

#inlogen .tab-content .tab-pane form .col-md-6 {
    margin-bottom: 0;
}

#inlogen .tab-content .tab-pane form input.form-control {
    height: 48px;
    border-radius: 0;
    font-size: 18px;
    background-color: rgba(238, 238, 238, 0.5);
}

#inlogen .tab-content .tab-pane form .help-block {
    text-align: center;
    color: red;
    margin-top: 10px;
}

#inlogen .tab-content .tab-pane form .button-container {
    margin-top: 20px;
}

#inlogen .tab-content .tab-pane form .button-container .btn.btn-primary {
    padding: 12px 45px;
    color: #222222;
    background-color: #0ad79b !important;
    border: none;
    outline: none;
    background-image: none;
    text-transform: uppercase;
}

#inlogen .tab-content .tab-pane form .button-container .btn.btn-link {
    font-family: "lato";
    color: #4b4b4a;
    font-weight: normal;
    padding-top: 12px;
    font-size: 18px;
}

#inlogen .tab-content .tab-pane form .button-container .btn.btn-link:hover {
    text-decoration: none;
}

form#feedback label {
    font-family: "lato";
    color: #4b4b4a;
    font-weight: normal;
    padding-top: 12px;
    font-size: 18px;
}

form#feedback div {
    margin-bottom: 10px;
}

form#feedback input.form-control {
    height: 48px;
    border-radius: 0;
    font-size: 18px;
    background-color: rgba(238, 238, 238, 0.5);
}

form#feedback textarea {
    background-color: rgba(238, 238, 238, 0.5);
    border-radius: 0;
    resize: vertical;
    font-size: 18px;
}

form#feedback .help-block {
    text-align: center;
    color: red;
    margin-top: 10px;
}

form#feedback .btn.btn-primary {
    padding: 12px 45px;
    color: #222222;
    background-color: #0ad79b !important;
    border: none;
    outline: none;
    background-image: none;
    text-transform: uppercase;
}

.feedback-address {
    font-family: "lato";
    color: #4b4b4a;
    font-weight: normal;
    padding-top: 12px;
    font-size: 18px;
    margin-bottom: 30px;
}

.succsess-send {
    display: inline-block;
    padding: 15px 40px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.succsess-send i {
    color: #4DA568;
    font-size: 40px;
}

.succsess-send p {
    font-size: 25px;
}

.reset-password {
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin-top: 30px;
}

.reset-password .panel-heading {
    color: #4b4b4a;
    background: #fff;
    font-family: "lato";
    padding: 15px;
    font-size: 25px;
}

.reset-password .form-horizontal .form-group {
    margin-top: 15px;
    text-align: center;
}

.reset-password .form-horizontal .form-group label {
    font-weight: normal;
    font-family: "lato";
    font-size: 18px;
    padding-top: 12px;
}

.reset-password .form-horizontal .form-group input {
    height: 48px;
    border-radius: 0;
    font-size: 18px;
}

.reset-password .form-horizontal .form-group .btn-primary {
    display: inline-block;
    margin: 0 auto;
    padding: 12px;
    box-sizing: border-box;
    color: #222222;
    background-color: #0ad79b !important;
    border: none;
    border-radius: 0;
    outline: none;
    background-image: none;
    text-transform: uppercase;
}

@media (max-width: 480px) {
    #inlogen ul {
        border-bottom: none;
        font-size: 40px;
        margin: 20px 0 40px 0;
    }
    #inlogen ul li {
        border-radius: 0;
        float: none;
    }
    #inlogen ul li a {
        border: none;
    }
    #inlogen .tab-content .tab-pane form label {
        margin-bottom: 10px;
    }
}


/*# sourceMappingURL=style.css.map */


/** my custom **/

.ni-main-wrapper {
    padding: 30px 0;
}

section.ni-main-wrapper-left h2 {
    margin: 0;
    padding: 10px;
    background-color: #4da568;
    color: #fff;
    margin-bottom: 20px;
}

aside.ni-main-wrapper-right h2 {
    margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'lato', Arial;
}

section.ni-main-wrapper-left h3 {
    background: #4da568;
    padding: 10px;
    margin: 0;
    color: #fff;
}

section.ni-main-wrapper-left .repeater-row h3 {
    background:none !important;
    color:#000;
    padding-left:0px;
    padding-right:0px;
}

button.btn.btn-primary.login-btn {
    color: #fff;
    padding: 4px 18px;
    margin-bottom: 20px;
    border-radius: 3px;
}

label.remember-me input {
    position: absolute;
    top: 10px;
    height: auto !important;
}

.forgotpassword {
  margin-right:15px;
}